.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.detail-description-styling a{
  color: #0c39ea;
}

.detail-description-styling  ol {
  list-style-type: decimal; /* Default is decimal, but you can use other options like lower-roman, upper-alpha, etc. */
  padding-left: 20px; /* Add some left padding to the list */
}

.detail-description-styling  ol li {
  margin-bottom: 10px; /* Add some spacing between list items */
}

/* Styling for unordered lists (ul) */
.detail-description-styling  ul {
  list-style-type: disc; /* Default is disc, but you can use other options like circle, square, etc. */
  padding-left: 20px; /* Add some left padding to the list */
}

.detail-description-styling  ul li {
  margin-bottom: 10px; /* Add some spacing between list items */
}

.detail-description-styling h2{
   font-size: 30px;
   font-weight: 400;
}

.detail-description-styling h3{
  font-size: 26px;
  font-weight: 500;
}

.detail-description-styling h4{
  font-size: 22px;
  font-weight: 450;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.landing-form {
  display: grid;
  grid-template-columns: 3fr 2fr;
}

.founder-message {
  display: grid;
  grid-template-columns: 4fr 3fr;
}

@media (max-width: 768px) {
  .landing-form {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .founder-message {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}
