@keyframes ldio-arj2khm927b {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.ldio-arj2khm927b div {
  position: absolute;
  width: 50%;
  height: 50%;
  border: 10px solid #bbcedd;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-arj2khm927b div {
  animation: ldio-arj2khm927b 1s linear infinite;
  top: 50%;
  left: 50%;
}
.loadingio-spinner-rolling-xufku1drzpe {
  width: 50px;
  height: 50px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-arj2khm927b {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-arj2khm927b div { box-sizing: content-box; }